<template>
  <v-sheet
    color="secondary lighten-1"
    class="d-flex align-center justify-center px-4"
    height="100%"
  >
    <v-card
      color="primary darken-2"
      class="pa-6"
      elevation="6"
    >
      <div class="mb-6 d-flex white--text align-center">
        <div style="width: 180px">
          <v-img
            src="/tesys-white.png"
            width="100%"
            height="100%"
            contain
          />
        </div>
        <div class="flex-grow-1 ml-4 pr-3">
          <h1 class="mt-n2">
            Cadastro de Contador
          </h1>
          <div class="text-subtitle-1 mt-n2">
            Pronto para fazer seu cadastro?
          </div>
        </div>
      </div>
      <v-form ref="registerForm" @submit.prevent="sendRegister()">
        <v-text-field
          v-model="register.name"
          label="Seu Nome Completo *"
          color="white"
          filled dark
          hide-details="auto"
          :rules="[$rules.required]"
          :disabled="loadingSave"
        />
        <v-text-field
          v-model="register.email"
          label="E-Mail de acesso *"
          color="white"
          filled dark
          hide-details="auto"
          :rules="[$rules.required, $rules.email]"
          :disabled="loadingSave"
        />
        <v-text-field
          v-model="register.password"
          label="Senha *"
          color="white"
          filled dark
          hide-details="auto"
          :rules="[$rules.required, $rules.passwordMin]"
          :disabled="loadingSave"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
        />
        <v-text-field
          label="Confirmar senha *"
          color="white"
          filled dark
          hide-details="auto"
          :disabled="loadingSave"
          :rules="[
            $rules.required,
            $rules.passwordMin,
            $rules.equalPassword(() => register.password)]"
          :append-icon="confirmRepeat ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="confirmRepeat = !confirmRepeat"
          :type="confirmRepeat ? 'text' : 'password'"
        />
        <v-text-field
          v-model="register.company.name"
          label="Razão Social da sua Empresa *"
          color="white"
          filled dark
          hide-details="auto"
          :rules="[$rules.required]"
          :disabled="loadingSave"
        />
        <v-text-field
          v-model="register.company.cnpj"
          label="CNPJ *"
          color="white"
          filled dark
          hide-details="auto"
          :rules="[$rules.required, $rules.cnpj]"
          v-mask="['##.###.###/####-##']"
          :disabled="loadingSave"
        />
        <v-checkbox
          :disabled="loadingSave"
          v-model="checkbox_register"
          dark
          color="white"
          label="Aceito os termos e condições de uso"
          hide-details="auto"
          :rules="[(val) => !!val || 'Você deve aceitar para continuar']"
        />
        <v-btn
          :loading="loadingSave"
          color="success"
          large block
          type="submit"
          class="mt-5"
        >
          Criar Conta
        </v-btn>
      </v-form>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "Register",

  data: () => ({
    loading: false,
    loadingSave: false,
    confirmRepeat: false,
    showPassword: false,
    checkbox_register: false,
    register: {
      name: "",
      emailregister: "",
      company: {
        name: null,
        cnpj: null,
      },
    },
  }),
  methods: {
    sendRegister() {
      if (!this.$refs.registerForm.validate()) {
        this.$showMessage("error", "Preencha todos os campos corretamente");
      } else {
        this.loadingSave = true;
        this.$axios
          .post("/accountant", this.register)
          .then((response) => {
            this.$showMessage("success", "Usuário Cadastrado com sucesso");
            this.$store.commit("setUser", response.data);
            this.$router.replace({
              name: "app",
            });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.$showMessage(
                  "warning",
                  "E-mail já cadastrado, por favor, utilize outro"
                );
                return;
              }
              if (error.response.status == 409) {
                this.$showMessage("warning", "CNPJ já cadastrado");
                return;
              }
              if (error.response.status == 401) {
                this.$showMessage("warning", "CNPJ inválido");
                return;
              }
            }
            this.$showMessage("error", "Falha Inesperada no Cadastro");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
  },
  created() {
    if (this.$store.state.logged) {
      this.$router.replace({ name: "app" });
    }
  },
};
</script>